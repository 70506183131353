export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.ecommerce.text',
        icon: 'ri-store-2-line',
        subItems: [
            {
                id: 8,
                label: 'menuitems.ecommerce.list.orders',
                link: '/ecommerce/orders'
            },
            {
                id: 6,
                label: 'menuitems.ecommerce.list.products',
                link: '/ecommerce/products'
            },
            {
                id: 8006,
                label: "menuitems.ecommerce.list.category",
                link: '/ecommerce/category'
            },
            {
                id: 8009,
                label: "menuitems.ecommerce.list.brand",
                link: '/ecommerce/brand'
            }
        ]
    },
    {
        id: 8000,
        label: 'menuitems.blog.text',
        icon: ' ri-article-line',
        subItems: [
            {
                id: 8010,
                label: 'menuitems.blog.list.blog',
                link: '/blog/index'
            },
            {
                id: 8011,
                label: 'menuitems.blog.list.category',
                link: '/blog/category'
            }
        ]
    },
    {
        id: 8001,
        label: 'menuitems.users.text',
        icon: "ri-account-circle-line",
        link: '/users/list'
    },
    {
        id: 8002,
        label: "menuitems.vendors.text",
        icon: "ri-store-2-fill",
        subItems: [
            // {
            //     id: 8013,
            //     label: "menuitems.vendors.list.vendor",
            //     link: '/vendors/list'
            // },
            {
                id: 8014,
                label: "menuitems.vendors.list.store",
                link: '/vendors/store'
            },
            {
                id: 80141,
                label: "menuitems.vendors.list.storeAdd",
                link: '/vendors/store/add'
            }
        ]
    },
    {
        id: 8018,
        label: "menuitems.inquiry.text",
        icon:  "ri-article-line",
        subItems: [
            {
                id: 8016,
                label: "menuitems.inquiry.text",
                link: '/inquiry/inquiry'
            }
        ]
    },
    {
        id: 8020,
        label: "menuitems.order.management",
        icon:  "ri-article-line",
        subItems: [
            {
                id: 8021,
                label: "menuitems.order.list",
                link: '/order/list'
            }
        ]
    },
    {
        id: 8010,
        label: "menuitems.extend.text",
        icon: "ri-stackshare-fill",
        subItems: [
            {
                id: 8004,
                label: "menuitems.extend.list.gallery",
                link: '/extend/gallery'
            },
            {
                id: 8008,
                label: "menuitems.extend.list.homePageSetting",
                link: '/extend/home-page'
            },
            {
                id: 8005,
                label: "menuitems.subscriptions.text",
                //icon: "ri-open-source-line",
                link: '/subscriptions/index'
            }
        ]
    },
    {
        id: 8003,
        label: "menuitems.auth.text",
        icon: "ri-settings-2-line",
        subItems: [
            {
                id: 8012,
                label: "menuitems.auth.list.admin",
                link: '/auth/admin'
            }
        ]
    },
    {
        id: 8015,
        label: "menuitems.exchange_rate.text",
        icon: "ri-settings-2-line",
        subItems: [
            {
                id: 8017,
                label: "menuitems.exchange_rate.list.exchange_rate",
                link: '/exchange_rate/list'
            }
        ]
    }
]